import miniLogo from './mini-logo.png';
import './style.less';
import React, {useMemo} from 'react';
import AuthLink from '../auth-link/auth-link';
import Global from '../../config/global';
import {Divider} from "antd";

export default function Logo(props) {
    const {collapsed, linkUrl} = props;
    const logo = useMemo(() => {
        return collapsed ? (
                <>
                    <AuthLink to={linkUrl}>
                        <div className={'mini-logo'}>
                            <img className='mini-logoImg' src={miniLogo} alt='mini-logoImg'/>
                        </div>
                    </AuthLink>

                </>
            ) :
            (
                <>
                    <AuthLink to={linkUrl}>
                        <div className={'logo'}>
                            <img className='logoImg' src={miniLogo} alt='logo'/>
                        </div>
                    </AuthLink>
                    <Divider style={{color: "white"}} plain orientation="center">{Global.AppName}</Divider>
                </>


            )
    }, [collapsed])

    return (
        <>
            {logo}
        </>
    );
}
