module.exports = {
    AppName: "无忧花管理系统",
    AppSimpleName: "无忧花",
    FooterText: "Ant Design ©2018 Created by Ant UED",
    HomePage: "/",
    UploadFileUrl: "https://img.hangzhoushuangchen.com/upload/files",
    LockPage: "/lock",
    CaptchaUrl: "/captcha",
    LoginPage: "https://admin.lsshenyan.com/",
    AuthUrl: "https://admin.lsshenyan.com/apps",
    InitMenu: false,
    USERINFO_KEY: "channel_userinfo_pro_fat",
    SECRET_KEY: "channel_secret_pro_fat",
    LOCAL_URL_KEY: "channel_local_url_pro_fat",
    BaseUrl: "https://wuyou.lsshenyan.com/admin",
}