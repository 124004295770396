import {Button, Result} from 'antd';
import {useNavigate} from 'react-router-dom';
import {FullContent} from '../content/full-content';
import loginUser from "../../store/loginUser";
import Global from "../../config/global";

export default function Error404(props) {
    const navigate = useNavigate();


    return <FullContent>
        <Result
            status='404'
            title='404'
            subTitle='当前访问的页面不存在！'
            extra={<Button type='primary' onClick={() => {
                if (loginUser.login) {
                    navigate(Global.HomePage)
                } else {
                    window.location.href = Global.LoginPage
                }
            }
            }>{loginUser.login ? "返回首页" :
                "前往登录页"}</Button>}
        />
    </FullContent>;
}
