import {Avatar, Dropdown, Menu} from "antd";
import {getColor, locationHref} from "../../config/util";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";
import Global from "../../config/global";
import {useNavigate} from "react-router-dom";
import EnvironmentalSwitchSelect from "../../config/EnvironmentalSwitchSelect";
import {LoginUser} from "../../store/loginUser";

type  AccountOptProps = {
    loginUser: LoginUser
}

export default function AccountOpt(props: AccountOptProps) {
    const {loginUser} = props
    const navigate = useNavigate();
    const optionItem = useMemo(() => {
        return [
            {
                key: '1',
                label: '退出登陆',
                icon: <LogoutOutlined/>,
                onClick: () => {
                    loginUser.logout()
                    locationHref(Global.LoginPage)
                }
            }
        ];
    }, [loginUser, navigate])

    return <>

        <div style={{paddingRight: 12, float: 'right'}}>
            <div>
                <Dropdown menu={{items:optionItem}}>
                    <div className={'action'}>
                        <Avatar size='small' style={{backgroundColor: getColor(loginUser.username)}}>
                            {(loginUser.username[0] || '').toUpperCase()}
                        </Avatar>
                        <span className={'userName'}>{loginUser.username}</span>
                        <UserOutlined/>
                    </div>
                </Dropdown>
            </div>
        </div>

        <div style={{paddingRight: 24, float: 'right'}}>
            <EnvironmentalSwitchSelect/>
        </div>
    </>
}
