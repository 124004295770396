import {CSSProperties, KeyboardEventHandler, ReactNode} from "react";
import {Button} from "antd";

type ActionPros = {
    children: ReactNode;
    onClick: ()=>void;
    loading: boolean;
    className: string;
    onKeyDown?: KeyboardEventHandler<T> | undefined;
    style: CSSProperties;
}
export default function Action(props: ActionPros) {

    return <Button onKeyDown={props.onKeyDown} type={"link"} className={props.className} loading={props.loading} style={{padding: 2, ...props.style}} onClick={(e) => {
        props?.onClick?.(e)
        return false
    }
    }>{props.children}</Button>
}
