import {BrowserRouter, HashRouter, HashRouterProps} from 'react-router-dom';
import React from 'react';

export const ROUTER_DATA = require('./router-cofig.json');
const RouterType = false ? HashRouter : BrowserRouter;

class Constant {
    FooterText: string;
    HomePage: string;
    LoginPage: string;
    LockPage: string;
    AppName: string;
    AppSimpleName: string;
    NODE_ENV: string;
    UploadTokenUrl: string;
    UploadFileUrl: string;
    CaptchaUrl: string;
    BaseUrl: string;
    AuthUrl: string;
    InitMenu: boolean;
    USERINFO_KEY: string;
    SECRET_KEY: string;
    LOCAL_URL_KEY: string;
    RouterType: React.Component<HashRouterProps, any>;
}

const Global: Constant = require("./.env." + process.env.NODE_ENV + ".js")
Global.RouterType = RouterType

Global.NODE_ENV = process.env.NODE_ENV
if (Global.NODE_ENV === "development") {
    const localUrl = localStorage.getItem(Global.LOCAL_URL_KEY)
    if (localUrl !== null && localUrl !== undefined && localUrl.length > 0) {
        Global.BaseUrl = localUrl
    }
}
Global.CaptchaUrl = Global.BaseUrl + Global.CaptchaUrl
export default Global;
